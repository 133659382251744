import {
  useMutation,
  useQueryClient,
  UseMutationOptions,
} from "@tanstack/react-query";
import { MUTATIONS, QUERIES } from "./queries";

import { deleteTransaction } from "../endpoints/Transactions";

export const useDeleteTransactionMutation = (
  id: number,
  options?: Omit<UseMutationOptions, "mutationKey" | "mutationFn">
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATIONS.deleteTransaction],
    mutationFn: () => deleteTransaction(id),
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.resetQueries({
        queryKey: [QUERIES.transactions, [QUERIES.transaction, id]],
      });
      await queryClient.resetQueries({ queryKey: [QUERIES.plot] });
      await queryClient.resetQueries({ queryKey: [QUERIES.expenses] });

      options?.onSuccess?.(data, variables, context);
    },
  });
};
