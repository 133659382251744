import { Flex, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type ActionProps = {
  Icon: React.ReactElement;
  to: string;
  title: string;
};

export const Action: React.FC<ActionProps> = (props) => {
  const { Icon, title, to } = props;

  return (
    <Link to={to}>
      <Wrapper>
        {Icon}
        <Typography.Text>{title}</Typography.Text>
      </Wrapper>
    </Link>
  );
};

const Wrapper = styled(Flex)`
  padding: ${({ theme }) =>
    `${theme.antd.paddingSM}px ${theme.antd.paddingMD}px`};
  gap: ${({ theme }) => theme.antd.paddingMD}px;
  border-top: 1px solid ${({ theme }) => theme.antd.colorBorderSecondary};
  width: 100%;

  :first-child {
    border-top-width: 0;
  }
`;
