import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../queries";
import { fetchPlotData, PlotPeriod } from "~shared/api/endpoints/Statitstics";
import { PlotQueryOptions } from "./types";

export const usePlotDataQuery = (
  period: PlotPeriod,
  options?: PlotQueryOptions
) => {
  return useQuery({
    queryKey: [QUERIES.plot, period],
    queryFn: () => fetchPlotData(period),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};
