import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MUTATIONS, QUERIES } from "./queries";

import {
  createTransaction,
  CreateTransactionData,
} from "../endpoints/Transactions";

export const useTransactionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATIONS.transaction],
    mutationFn: (data: CreateTransactionData) => createTransaction(data),
    onSuccess: async () => {
      await queryClient.resetQueries({ queryKey: [QUERIES.transactions] });
      await queryClient.resetQueries({ queryKey: [QUERIES.plot] });
      await queryClient.resetQueries({ queryKey: [QUERIES.expenses] });
    },
  });
};
