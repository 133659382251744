import { PaginatedResponse } from "~shared/api/types";
import { fetchHelper } from "~shared/helpers/fetchHelper";
import { Transaction } from "./types";

export const fetchTransactions = (params: { page: number; limit?: number }) => {
  return fetchHelper<PaginatedResponse<Transaction>>({
    url: `/ledger?page=${params.page}${
      params.limit ? `&size=${params.limit}` : ""
    }&sort=date:desc`,
  });
};
