import { Avatar } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useUserContext } from "~shared/contexts/UserContext";

export const UserAvatar = () => {
  const { user } = useUserContext();

  if (!user) {
    return null;
  }

  const userName = user.username.split("")[0].toUpperCase();

  return (
    <Link to="/app/settings">
      <StyledAvatar shape="circle">{userName}</StyledAvatar>
    </Link>
  );
};

const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.antd.colorBgContainer};
  color: ${({ theme }) => theme.antd.colorText} !important;
  border: 1px solid ${({ theme }) => theme.antd.colorBorderSecondary};
`;
