import dayjs from "dayjs";
import { PlotData } from "~shared/api/endpoints/Statitstics";

export type DateTransformer = (date: string) => string;

type CreatePlotDataOptions = {
  dateTransformer: DateTransformer;
  data?: PlotData[];
};

export const createPlotData = (options: CreatePlotDataOptions) => {
  const { data, dateTransformer } = options;

  const dataPlot =
    data?.map((element) => ({
      date: dateTransformer(element.date),
      total: element.total,
    })) ?? [];

  const dataMax = dataPlot.reduce(
    (max, obj) => (parseFloat(obj.total) > max ? parseFloat(obj.total) : max),
    0
  );

  const totalElementsWithValue = dataPlot.reduce((acc, value) => {
    if (parseFloat(value.total) > 0) {
      acc.push(value.date);
    }
    return acc;
  }, [] as string[]).length;

  const totalAmount = dataPlot.reduce(
    (max, obj) => parseFloat(obj.total) + max,
    0
  );

  const dataAverage = totalAmount / totalElementsWithValue;

  return {
    plot: dataPlot,
    max: dataMax,
    avg: dataAverage,
  };
};

export const dayDateTransformer: DateTransformer = (date) =>
  NUMBER_TO_DAY[dayjs(date).day()];

export const yearDateTransformer: DateTransformer = (date) =>
  NUMBER_TO_MONTH[dayjs(date).month()];

export const monthDateTransformer: DateTransformer = (date) => {
  return dayjs(date).format("DD");
};

const NUMBER_TO_DAY = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  0: "Sun",
};

const NUMBER_TO_MONTH: Record<number, string> = {
  0: "Ja",
  1: "Fe",
  2: "Ma",
  3: "Ap",
  4: "My",
  5: "Jn",
  6: "Jl",
  7: "Ag",
  8: "St",
  9: "Oc",
  10: "Nv",
  11: "Dc",
};
