import { Layout } from "antd";
import React from "react";
import { Header } from "./Header";
import { LayoutContent } from "./LayoutContent";
import { Link } from "react-router-dom";
import { BackIcon } from "../BackIcon";

type FormLayoutProps = {
  title: string;
  backUrl: string;
};

export const FormLayout: React.FC<React.PropsWithChildren<FormLayoutProps>> = (
  props
) => {
  const { backUrl, children, title } = props;

  return (
    <Layout style={{ height: "100%" }}>
      <Header
        Start={
          <Link to={backUrl}>
            <BackIcon />
          </Link>
        }
        title={title}
      />
      <LayoutContent>{children}</LayoutContent>
    </Layout>
  );
};
