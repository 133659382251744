import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "./queries";
import { fetchTransactions } from "../endpoints/Transactions";

export const useTransactionsSummaryQuery = () => {
  return useQuery({
    queryKey: [QUERIES.transactions, "summary"],
    queryFn: () => fetchTransactions({ page: 1, limit: 4 }),
  });
};
