import React from "react";
import { Flex, Segmented } from "antd";
import { PageLayout, Widget } from "~shared/ui";
import { BarChart, PieChart } from "./shared";
import {
  useExpensesQuery,
  usePlotDataQuery,
} from "~shared/api/queries/statistics";
import {
  createPlotData,
  DateTransformer,
  dayDateTransformer,
  monthDateTransformer,
  yearDateTransformer,
} from "./helpers";
import { PlotPeriod } from "~shared/api/endpoints/Statitstics";
import { ExpensesCategoryList } from "./ui";
import styled from "styled-components";
import { BarChartOutlined, PieChartOutlined } from "@ant-design/icons";

export const StatisticsPage: React.FC = () => {
  const [chartType, setChartType] = React.useState<"bars" | "pie">("bars");
  const [view, setView] = React.useState<PlotPeriod>(
    SETGMENTED_OPTIONS[0].value
  );

  const { data, isLoading } = usePlotDataQuery(view);
  const { data: expenses } = useExpensesQuery(view);

  const monthlyData = React.useMemo(
    () =>
      createPlotData({
        data,
        dateTransformer: VIEW_TO_DATE_TRANSFORMER[view],
      }),
    [data, view]
  );

  return (
    <PageLayout title="Statistics">
      <Wrapper>
        <Graph>
          <Widget isLoading={isLoading}>
            <Container>
              <Buttons
                options={[
                  { value: "bars", icon: <BarChartOutlined /> },
                  { value: "pie", icon: <PieChartOutlined /> },
                ]}
                value={chartType}
                onChange={(type) => setChartType(type as any)}
              />
              {chartType === "bars" ? (
                <BarChart
                  data={monthlyData.plot}
                  maxValue={monthlyData.max}
                  average={monthlyData.avg}
                />
              ) : (
                <PieChart data={expenses ?? []} />
              )}
            </Container>
          </Widget>
          <Segmented
            value={view}
            onChange={(value) => setView(value)}
            block
            options={SETGMENTED_OPTIONS}
          />
        </Graph>
        <ExpensesCategoryList data={expenses ?? []} />
      </Wrapper>
    </PageLayout>
  );
};

type SegmentedValue = { label: string; value: PlotPeriod };

const SETGMENTED_OPTIONS: SegmentedValue[] = [
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Semester", value: "semester" },
  { label: "Year", value: "year" },
];

const VIEW_TO_DATE_TRANSFORMER: Record<PlotPeriod, DateTransformer> = {
  "current-month": dayDateTransformer,
  month: monthDateTransformer,
  semester: yearDateTransformer,
  year: yearDateTransformer,
  week: dayDateTransformer,
};

const Wrapper = styled(Flex).attrs({ vertical: true })`
  gap: ${({ theme }) => theme.antd.marginLG}px;
`;

const Buttons = styled(Segmented)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
`;

const Graph = styled(Flex).attrs({ vertical: true })`
  gap: ${({ theme }) => theme.antd.marginXS}px;
`;

const Container = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
`;
