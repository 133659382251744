import React from "react";
import { Link } from "react-router-dom";
import { BackIcon, PageLayout } from "~shared/ui";
import { ActionsWidget, UserDataWidget } from "./ui";
import { Flex } from "antd";
import styled from "styled-components";

export const SettingsPage = () => {
  return (
    <PageLayout
      title="Settings"
      HeaderStart={
        <Link to="/app">
          <BackIcon />
        </Link>
      }
    >
      <Widgets>
        <UserDataWidget />
        <ActionsWidget />
      </Widgets>
    </PageLayout>
  );
};

const Widgets = styled(Flex).attrs({ vertical: true })`
  gap: ${({ theme }) => theme.antd.marginXL}px;
`;
