import { Flex, Skeleton } from "antd";
import styled from "styled-components";

export const Loading = () => {
  return (
    <Container>
      <Background style={{ width: "100%", height: "100%" }} />
    </Container>
  );
};

const Background = styled(Skeleton.Button).attrs({
  active: true,
  block: true,
})``;

const Container = styled(Flex)`
  width: 100% !important;
  height: 100% !important;
  min-height: 200px;
`;
