import React from "react";
import { Flex, List, Typography } from "antd";
import styled from "styled-components";
import { Transaction } from "~shared/api/endpoints/Transactions";
import { Date } from "~shared/ui";

type TransactionListItemProps = {
  transaction: Transaction;
};

export const TransactionListItem: React.FC<TransactionListItemProps> = (
  props
) => {
  const {
    transaction: { amount, date, name },
  } = props;

  return (
    <StyledListItem>
      <Flex align="space-between" justify="space-between" flex={1}>
        <Date date={date} />
        <Flex align="center" justify="space-between" flex={1}>
          <Title>{name}</Title>
          <Amount strong>{parseFloat(amount).toFixed(2)} €</Amount>
        </Flex>
      </Flex>
    </StyledListItem>
  );
};

const StyledListItem = styled(List.Item)`
  padding: ${({ theme }) =>
    `${theme.antd.paddingMD}px ${theme.antd.paddingXL}px`} !important;
  border-bottom: 1px solid ${({ theme }) => theme.antd.colorBorder} !important;
`;

const Title = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.antd.fontSizeLG}px;
  text-transform: capitalize;
`;

const Amount = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.antd.fontSizeLG}px;
`;
