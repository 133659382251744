import { FileAddOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import React from "react";
import { PageLayout, UserAvatar } from "~shared/ui";
import { MonthlyExpenses, TransactionsSummary, WeeklyChart } from "./ui";

export const HomePage = () => {
  return (
    <PageLayout
      title="Home"
      floatingButton={floatingButtonConfig}
      HeaderStart={<UserAvatar />}
    >
      <Flex align="center" justify="center" vertical gap={25}>
        <MonthlyExpenses />
        <WeeklyChart />
        <TransactionsSummary />
      </Flex>
    </PageLayout>
  );
};

const floatingButtonConfig = {
  icon: <FileAddOutlined />,
  link: "/app/transactions/new",
};
