import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { CategoryExpense } from "~shared/api/endpoints/Statitstics";
import { EUR } from "~shared/helpers/currency";

type PiechartProps = {
  data: CategoryExpense[];
};

export const PieChart: React.FC<PiechartProps> = (props) => {
  const { data } = props;

  const plotData = data.map((data) => ({
    label: data.categoryName,
    id: data.categoryName,
    value: data.total,
  }));

  return (
    <ResponsivePie
      innerRadius={0.5}
      margin={{ top: 40, bottom: 40, left: 40, right: 40 }}
      data={plotData}
      valueFormat={(value) => EUR.format(value)}
    />
  );
};
