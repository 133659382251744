import { Card, Typography } from "antd";
import React from "react";
import styled, { css } from "styled-components";
import { Loading } from "./Loading";

type WidgetProps = {
  padded?: boolean;
  isLoading?: boolean;
  title?: string;
  LoadingState?: React.ReactNode;
};

export const Widget: React.FC<React.PropsWithChildren<WidgetProps>> = (
  props
) => {
  const { children, isLoading, LoadingState, padded, title } = props;

  return (
    <Container>
      {title && <Title strong>{title}</Title>}
      <Content $padded={padded}>
        {isLoading ? LoadingState ?? <Loading /> : children}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${({ theme }) => theme.antd.marginXS}px;
`;

const Content = styled(Card)<{ $padded?: boolean }>`
  width: 100%;

  ${({ $padded }) =>
    !$padded &&
    css`
      .ant-card-body {
        padding: 0 !important;
      }
    `}
`;

const Title = styled(Typography.Text)`
  margin-left: ${({ theme }) => theme.antd.marginXXS}px;
`;
