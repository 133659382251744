import { Flex, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTransactionsSummaryQuery } from "~shared/api/queries/useTransactionsSummaryQuery";
import { TransactionSummaryItem } from "./TransactionSummaryItem";
import { SummaryLoadingState } from "./SummaryLoadingState";
import { Widget } from "~shared/ui";

export const TransactionsSummary = () => {
  const { data, isLoading } = useTransactionsSummaryQuery();

  return (
    <Widget
      title="Expenses"
      isLoading={isLoading}
      LoadingState={<SummaryLoadingState />}
    >
      {data?.data.map((transaction) => (
        <Link key={transaction.id} to={`/app/transactions/${transaction.id}`}>
          <TransactionSummaryItem transaction={transaction} />
        </Link>
      ))}
      <Link to="/app/transactions">
        <SeeAllContainer>
          <Typography.Text>SEE ALL</Typography.Text>
        </SeeAllContainer>
      </Link>
    </Widget>
  );
};

const SeeAllContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.antd.paddingMD}px;
`;
