import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthPage, LogoutPage, RegisterPage } from "~modules/Auth";
import { PrivateRoute } from "./PrivateRoute";
import {
  TransactionEditPage,
  TransactionsFormPage,
} from "~modules/TransactionsForm";
import { TransactionsPage } from "~modules/Transactions";
import { CategoriesListPage } from "~modules/CategoriesList";
import {
  CreateCategoryPage,
  UpdateCategoryPage,
} from "~modules/CategoriesForm";
import { HomePage } from "~modules/Home";
import { SettingsPage } from "~modules/Settings";
import { StatisticsPage } from "~modules/Statistics";

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={AuthPage} />
        <Route index path="/login" Component={AuthPage} />
        <Route path="register" Component={RegisterPage} />
        <Route path="logout" Component={LogoutPage} />
        <Route path="app/*" Component={PrivateRoute}>
          <Route path="" Component={HomePage} />
          <Route path="transactions" Component={TransactionsPage} />
          <Route path="transactions/new" Component={TransactionsFormPage} />
          <Route path="transactions/:id" Component={TransactionEditPage} />
          <Route path="categories" Component={CategoriesListPage} />
          <Route path="categories/new" Component={CreateCategoryPage} />
          <Route path="categories/:id" Component={UpdateCategoryPage} />
          <Route path="statistics" Component={StatisticsPage} />
          <Route path="settings" Component={SettingsPage} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
