import { Button, List } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTransactionsQuery } from "~shared/api/queries";
import { BackIcon, EmptyData, PageLayout } from "~shared/ui";
import { TransactionListItem } from "./ui";
import { FileAddOutlined } from "@ant-design/icons";

export const TransactionsPage = () => {
  const navigate = useNavigate();
  const { data, hasNextPage, fetchNextPage, isLoading } = useTransactionsQuery({
    page: 1,
    limit: 20,
  });

  const transactions = React.useMemo(
    () =>
      data?.pages
        .flat()
        .map(({ data }) => data)
        .flat() ?? [],
    [data]
  );

  return (
    <PageLayout
      title="Transactions"
      padded={false}
      isLoading={isLoading}
      withNavigation={false}
      HeaderStart={
        <Link to="/app">
          <BackIcon />
        </Link>
      }
      floatingButton={floatingButtonConfig}
    >
      {!transactions?.length ? (
        <EmptyData
          buttonLabel="Create Transaction"
          description="Seems like you don't have any transactions yet, create one!"
          onClick={() => navigate("/app/transactions/new")}
        />
      ) : (
        <List
          renderItem={(item) => (
            <Link to={`/app/transactions/${item.id}`}>
              <TransactionListItem transaction={item} />
            </Link>
          )}
          dataSource={transactions}
          loadMore={
            hasNextPage ? (
              <Button onClick={() => fetchNextPage()}>Load More</Button>
            ) : null
          }
        />
      )}
    </PageLayout>
  );
};

const floatingButtonConfig = {
  icon: <FileAddOutlined />,
  link: "/app/transactions/new",
};
