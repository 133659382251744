import { Flex, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { useUserContext } from "~shared/contexts/UserContext";
import { Widget } from "~shared/ui";

export const UserDataWidget: React.FC = () => {
  const { user } = useUserContext();

  return (
    <Widget padded title="User Data">
      <Wrapper>
        <Flex vertical>
          <Typography.Text strong>Username</Typography.Text>
          <Typography.Text>{user?.username}</Typography.Text>
        </Flex>
        <Flex vertical>
          <Typography.Text strong>Email</Typography.Text>
          <Typography.Text>{user?.email}</Typography.Text>
        </Flex>
      </Wrapper>
    </Widget>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.antd.marginMD}px;
`;
