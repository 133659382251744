import React from "react";
import { Widget } from "~shared/ui";
import { Action } from "./Action";
import { LogoutOutlined } from "@ant-design/icons";

export const ActionsWidget = () => {
  return (
    <Widget>
      <Action Icon={<LogoutOutlined />} to="/logout" title="Logout" />
    </Widget>
  );
};
