import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MUTATIONS, QUERIES } from "./queries";

import {
  updateTransaction,
  UpdateTransactionData,
} from "../endpoints/Transactions";

export const useUdateTransactionMutation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATIONS.transaction],
    mutationFn: (data: UpdateTransactionData) => updateTransaction(id, data),
    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: [QUERIES.transactions, [QUERIES.transaction, id]],
      });
      await queryClient.resetQueries({ queryKey: [QUERIES.plot] });
      await queryClient.resetQueries({ queryKey: [QUERIES.expenses] });
    },
  });
};
