import { Flex, List, Skeleton } from "antd";
import React from "react";
import styled from "styled-components";

export const SummaryLoadingState = () => {
  return (
    <>
      <StyledListItem>
        <Flex align="center" justify="space-between" gap={20} flex={1}>
          <Skeleton.Button size="default" shape="circle" />
          <Flex flex={1}>
            <Skeleton.Input />
          </Flex>
          <Skeleton.Button size="small" shape="square" />
        </Flex>
      </StyledListItem>
      <StyledListItem>
        <Flex align="center" justify="space-between" gap={20} flex={1}>
          <Skeleton.Button size="default" shape="circle" />
          <Flex flex={1}>
            <Skeleton.Input />
          </Flex>
          <Skeleton.Button size="small" shape="square" />
        </Flex>
      </StyledListItem>
      <StyledListItem>
        <Flex align="center" justify="space-between" gap={20} flex={1}>
          <Skeleton.Button size="default" shape="circle" />
          <Flex flex={1}>
            <Skeleton.Input />
          </Flex>
          <Skeleton.Button size="small" shape="square" />
        </Flex>
      </StyledListItem>
      <StyledListItem>
        <Flex align="center" justify="space-between" gap={20} flex={1}>
          <Skeleton.Button size="large" shape="circle" />
          <Flex flex={1}>
            <Skeleton.Input />
          </Flex>
          <Skeleton.Button size="small" shape="square" />
        </Flex>
      </StyledListItem>
      <Button>
        <Skeleton.Button />
      </Button>
    </>
  );
};

const StyledListItem = styled(List.Item)`
  padding: ${({ theme }) =>
    `${theme.antd.paddingSM}px ${theme.antd.paddingMD}px`} !important;
  border-bottom: 1px solid ${({ theme }) => theme.antd.colorBorderSecondary} !important;
`;

const Button = styled(Flex).attrs({ align: "center", justify: "center" })`
  padding: ${({ theme }) => theme.antd.paddingSM}px;
`;
