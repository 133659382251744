import { Flex, Skeleton, Typography } from "antd";
import styled from "styled-components";
import { usePlotDataQuery } from "~shared/api/queries/statistics";
import { EUR } from "~shared/helpers/currency";

export const MonthlyExpenses = () => {
  const { data, isLoading } = usePlotDataQuery("current-month");

  return (
    <Flex align="center" justify="center" vertical>
      <Typography.Text type="secondary">Monthly Expenses</Typography.Text>
      {isLoading ? (
        <Loading active block />
      ) : (
        <Currency>{EUR.format(parseFloat(data?.[0]?.total ?? "0"))}</Currency>
      )}
    </Flex>
  );
};

const Currency = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.antd.fontSizeHeading2}px;
`;

const Loading = styled(Skeleton.Button)`
  height: 30px;
  width: 250px;
`;
