import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../queries";
import {
  CategoryExpense,
  fetchExpenses,
  PlotPeriod,
} from "~shared/api/endpoints/Statitstics";
import { ExtendQueryOptions } from "~shared/api/types";

export const useExpensesQuery = (
  period: PlotPeriod,
  options?: ExtendQueryOptions<CategoryExpense[]>
) => {
  return useQuery({
    queryKey: [QUERIES.expenses, period],
    queryFn: () => fetchExpenses(period),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};
